<template>
  <DisplayLoading
    :isLoading="isLoading"
    :isError="isError"
    :loadingMessage="loadingMessage"
    :errorMessage="errorMessage"
  >
    <div v-if="pageData">
      <PageHeader text="Критерии">
        <v-btn small text @click="onClean" :disabled="!hasCriteries"
          ><v-icon class="mr-1">mdi-broom</v-icon>Очистить критерии</v-btn
        >
        <LoadCriteriaFromFile
          :projectNosologiaId="projectNosologiaId"
          :projectId="projectId"
          :hasCriteries="hasCriteries"
          @update="update"
        />
      </PageHeader>

      <v-data-table
        no-data-text="У этого заболевания (состояния) в этом проекте еще критериев"
        :items="criteries"
        :headers="headers"
        hide-default-footer
        disable-pagination
        class="mb-8"
      ></v-data-table>

      <BackToProjectButton />
    </div>
    <div v-else>Нет критериев</div>
  </DisplayLoading>
</template>

<script>
import DisplayLoading from '@/components/common/DisplayLoading.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import BackToProjectButton from '@/components/buttons/BackToProjectButton.vue'
import LoadCriteriaFromFile from '@/components/project/LoadCriteriaFromFile.vue'

import { routeParams } from '@/mixins/routeParams'
import { loadPageData } from '@/mixins/loadPageData'
import { sendDataWithToastNoRedirect } from '@/mixins/sendDataWithToastNORedirect'

import { cleanAllCriteries } from '@/lib/requestsAPI'

import { API_PROJECTS_NOSOLOGIA_IDENTITY } from '@/store/const/apiPaths'

export default {
  data() {
    return {
      loadingMessage: 'Загружаю критерии для проекта',
      headers: [
        { text: 'Критерий', value: 'name' },
        { text: 'Критерий в приказе 203н', value: 'name_203n' },
      ],
      preload: [],
    }
  },
  components: {
    PageHeader,
    BackToProjectButton,
    LoadCriteriaFromFile,
    DisplayLoading,
  },
  mixins: [routeParams, loadPageData, sendDataWithToastNoRedirect],
  computed: {
    loadUrl() {
      return `${API_PROJECTS_NOSOLOGIA_IDENTITY}?project_id=${this.projectId}&nosology_id=${this.nosologiaId}`
    },
    errorMessage() {
      return `Не удалось загрузить критерии для проекта: ${this.projectId} и заболевания: ${this.nosologiaId}`
    },
    projectNosologia() {
      return this.pageData
    },
    criteries() {
      return this.projectNosologia.criteria
    },
    hasCriteries() {
      return this.projectNosologia.criteria?.length
    },
    projectNosologiaId() {
      return this.projectNosologia.id
    },
    allSaved() {
      if (this.preload.length) return false
      return true
    },
  },
  methods: {
    onClean() {
      this.sendDataWithToastNoRedirect({
        action: cleanAllCriteries,
        onFinally: this.update,
        data: {
          projects_nosology_id: this.projectNosologiaId,
          project_id: this.projectId,
        },
        successMessage: 'Открепили от проекта',
        errorMessage: `Не удалось очистить критерии`,
      })
    },
  },
}
</script>
