<template>
  <div>
    <v-btn text small @click="onFileButtonClick" :loading="processing"
      ><v-icon class="mr-1">mdi-paperclip</v-icon>Загрузить из файла</v-btn
    >
    <input ref="file" type="file" class="d-none" @change="handleFileLoad" />

    <v-dialog
      v-model="preview"
      scrollable
      max-width="800"
      @click:outside="onCancel"
    >
      <v-card>
        <v-card-title>Предварительный просмотр</v-card-title>
        <v-switch
          v-model="withHeaders"
          :label="switchLabel"
          class="ml-6"
        ></v-switch>
        <v-card-text style="height: 300px">
          <v-data-table
            :items="significantCriteries"
            :headers="headers"
            hide-default-footer
            disable-pagination
          >
          </v-data-table>
        </v-card-text>
        <div v-if="hasCriteries" class="ml-6 mt-4">
          Строки будут добавлены к существующим. <br />
          Если нужно заменить — отмените предварительный просмотр и нажмите
          «очистить критерии»
        </div>
        <v-card-actions>
          <v-btn color="primary" @click="onAdd" :disabled="excelHasErrors"
            >Добавить</v-btn
          >
          <v-btn @click="onCancel">Отмена</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ExcelJS from 'exceljs'
import { addCriteries } from '@/lib/requestsAPI'
import { sendDataWithToastNoRedirect } from '@/mixins/sendDataWithToastNORedirect'

export default {
  props: ['projectNosologiaId', 'projectId', 'hasCriteries'],
  mixins: [sendDataWithToastNoRedirect],
  computed: {
    significantLines() {
      return this.withHeaders
        ? this.criteries.length - 1
        : this.criteries.length
    },
    significantCriteries() {
      return this.withHeaders ? this.criteries.slice(1) : this.criteries
    },
    switchLabel() {
      return `Убрать заголовки (Строк: ${this.significantLines})`
    },
  },
  data() {
    return {
      withHeaders: false,
      processing: false,
      preview: false,
      excelHasErrors: false,
      criteries: [],
      headers: [
        { text: 'Критерий', value: 'name' },
        { text: 'Критерий в приказе 203н', value: 'name_203n' },
      ],
    }
  },
  methods: {
    onFileButtonClick() {
      this.$refs.file.click()
    },
    onCancel() {
      this.preview = false
      this.criteries = []
      this.$refs.file.value = ''
    },
    onAdd() {
      const criteries = this.significantCriteries.map(o => ({
        ...o,
        projects_nosology_id: this.projectNosologiaId,
      }))
      this.preview = false
      this.sendDataWithToastNoRedirect({
        action: addCriteries,
        onSuccess: this.onSuccess,
        data: { criteries, project_id: this.projectId },
        successMessage: 'Добавили критерии',
        errorMessage: `Не удалось добавить критерии`,
      })
    },
    onSuccess() {
      this.$emit('update')
    },
    handleFileLoad(evt) {
      const file = evt.target.files[0]
      if (!file) return
      this.processing = true
      const reader = new FileReader()
      const workbook = new ExcelJS.Workbook()
      reader.readAsArrayBuffer(file)
      reader.onload = async () => {
        await workbook.xlsx.load(reader.result)
        const worksheet = workbook.worksheets[0]
        const rows = worksheet.getRows(1, worksheet.lastRow.number)
        this.criteries = rows
          .map(r => ({
            name: r.getCell('A').value,
            name_203n: r.getCell('B').value,
          }))
          .filter(r => r.name)
        this.checkFile()
        this.processing = false
        this.preview = true
      }
    },
    checkFile() {
      this.excelHasErrors = !!this.criteries.find(
        c =>
          typeof c.name !== 'string' ||
          (c.name_203n && typeof c.name_203n !== 'string')
      )
      if (this.excelHasErrors) {
        this.$toast.warning('Ошибка в файле')
      }
    },
  },
}
</script>
